import { ChatbotRdaMessage } from "./chatbot-rda-message";

export class ChatbotRda {
    readonly sessionId: string;
    readonly messages: ChatbotRdaMessage[];

    constructor(sessionId: string, messages: ChatbotRdaMessage[]) {
        this.sessionId = sessionId;
        this.messages = messages;
    }

    concatenateMessages(): string {
        return this.messages
            .map(message => (message.isUserMessage ? 'Utente: ' : 'Chatbot: ') + message.text)
            .join('\n');
    }

    toJson(): string {
        return JSON.stringify(this);
    }

    static fromJson(content: string): ChatbotRda {
        try {
            const junk = JSON.parse(content);
            return new ChatbotRda(junk.sessionId, junk.messages);
        } catch (e) {
            return null;
        }
    }
}